<template>

  <div class="d-flex flex-column">

    <h4 class="header flex-grow-0">
      Senest afspillede
    </h4>

    <div class="playlist flex-fill">
      <table class="table table-striped">
        <tbody>
        <tr v-for="t in playlist.slice(0).reverse()">
          <th>
            {{ t.time }}
          </th>
          <td width="100%">
            <strong>{{ t.track }}</strong><br />
            {{ t.artist }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>

<style lang="scss" scoped>
  .header {
    background-color: #848484;
    width: 100%;
    padding: 13px 15px;
    text-transform: uppercase;
    margin: 0;
    box-sizing: border-box;
    font-family: open_sansextrabold;
    color: white;
    font-size: 18px;
    background-image: linear-gradient(-180deg, #797979 0%, #9C9C9C 30%);
  }

  .playlist {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    th {
      font-size: 16px;
      vertical-align: middle;
      white-space: nowrap;
    }
  }
</style>

<script>
  import Playlist from './../services/Playlist';
  import { routeInfo } from '../router/index';

  export default {
    data: () => ({
      playlist: Playlist.playlist,
    }),
    mounted: () => {
      routeInfo.playlistIsOpen = true;
    },
    destroyed: () => {
      routeInfo.playlistIsOpen = false;
    },
  };

</script>