/* eslint-disable no-alert */
import Playing from './Playing';
import IcecastMetadataPlayer from "icecast-metadata-player";

const URLS = {
  mp3: 'https://stream.vinylhits.dk/vinylmp3',
  aac: 'https://stream.vinylhits.dk/vinylaac',
};

let icyEl = null;

/**
 * Private methods and variables.
 */
const priv = {
  firstCall: true,
  player: null,
  mcDefaults: {
    cover: 'img/icon-heart.png',
    isPlaying: true,
    dismissable: true,
    hasPrev: false,
    hasNext: false,
    hasClose: true,
    track: Playing.title,
    artist: Playing.artist,
    ticker: `Spiller nu "${Playing.title}"`,
    hidden: false,
    silent: false,
  },

  updateMcDefault() {
    priv.mcDefaults.track = Playing.title || 'VinylHits';
    priv.mcDefaults.artist = Playing.artist || 'Indlæser...';
    priv.mcDefaults.cover = Playing.coverUrl || 'img/icon-heart.png';
    priv.mcDefaults.ticker = `Spiller nu "${Playing.title}"`;
  },

  play() {
    priv.player.isLoading = true;

    if (window.device && window.device && window.device.platform === 'iOS') {
      window.cordova.plugins.backgroundMode.enable();
    }

    if (!icyEl) {
      icyEl = new IcecastMetadataPlayer(
        URLS.aac,
        {
          metadataTypes: ['ogg'],
          bufferLength: 5,
          retryTimeout: 60,
          retryDelayMin: 1,
          retryDelayMax: 5,
          onPlay() {
            priv.setIsPlaying();
          }
        },
      );
    }
    icyEl.play();
  },

  setIsPlaying: () => {
    priv.player.isPlaying = true;
    priv.player.isLoading = false;

    if (window.MusicControls) {
      window.MusicControls.updateIsPlaying(true);
      priv.recreateMc();
    }
  },

  stop: (alreadyStopped = false) => {

    priv.player.isPlaying = false;
    priv.player.isLoading = false;

    if (window.MusicControls) window.MusicControls.destroy();

    if (window.device && window.device && window.device.platform === 'iOS') {
      window.cordova.plugins.backgroundMode.disable();
    }

    priv.mcLastTitle = null;
    icyEl.stop();
  },

  ignoreDoubleTap: false,
  playOrStop() {
    if (priv.ignoreDoubleTap) return;
    priv.ignoreDoubleTap = true;
    window.setTimeout(() => {
      priv.ignoreDoubleTap = false;
    }, 1000);

    if (!priv.player.isPlaying && !priv.player.isLoading) {
      priv.play();
    } else {
      priv.stop();
    }
  },

  maybeConstruct(p) {
    priv.player = p;

    if (!priv.firstCall) return;
    priv.firstCall = false;
  },

  mcFirstTime: true,
  mcLastTitle: null,
  recreateMc: () => {
    priv.updateMcDefault();
    if (!window.MusicControls) return;

    if (priv.mcLastTitle && priv.mcLastTitle === priv.mcDefaults.track) return;
    if (!priv.player.isPlaying) return;
    priv.mcLastTitle = priv.mcDefaults.track;

    if (window.device && window.device && window.device.platform === 'iOS') {
      window.cordova.plugins.backgroundMode.enable();
    }

    window.MusicControls.destroy();
    window.MusicControls.create(priv.mcDefaults);

    if (priv.mcFirstTime) {
      window.MusicControls.subscribe((ev) => {
        const msg = JSON.parse(ev);

        switch (msg.message) {
          case 'music-controls-pause':
            priv.stop();

            priv.player.isPlaying = false;
            priv.player.isLoading = false;
            window.MusicControls.updateIsPlaying(false);

            break;

          case 'music-controls-destroy':
            priv.stop();
            break;

          case 'music-controls-play':
            priv.play();
            window.MusicControls.updateIsPlaying(true);

            break;

          case 'music-controls-toggle-play-pause':
            priv.stop();
            break;

          case 'music-controls-headset-unplugged':
          case 'music-controls-headset-plugged':
            priv.stop();

            if (window.MusicControls) {
              window.MusicControls.destroy();
            }

            break;

          default:
            break;
        }
      });

      window.MusicControls.listen();
    }

    priv.mcFirstTime = false;
  },
};

/**
 * The player (public methods and variables with status)
 */
const Player = {
  isPlaying: false,
  isLoading: false,

  init: () => {
    priv.maybeConstruct(Player);
  },

  togglePlay() {
    if (Player.isPlaying || Player.isLoading) {
      priv.stop();
    } else {
      priv.play();
    }
  },

  recreateMc() {
    priv.recreateMc();
  },
};

export default Player;
