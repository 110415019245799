<template>

  <div class="d-flex flex-column h-100">
    <Nav class="flex-grow-0"></Nav>
    <router-view></router-view>
  </div>

</template>

<script>
  import Nav from './components/Nav';
  import Player from './services/Player';

  export default {
    components: {
      Nav,
    },
    mounted: () => {
      Player.init();
    },
  };
</script>

<style lang="scss">
  @import '~bootstrap/scss/bootstrap.scss';
  @import 'assets/font-open-sans/opensans_regular_macroman/stylesheet.css';
  @import 'assets/font-open-sans/opensans_extrabold_macroman/stylesheet.css';

  html, body {
    height: 100%;
  }
</style>
