const Axios = require('axios');

const Playlist = [];

const updatePlaylist = async () => {
  const playlistReq = await Axios.get('https://vinylhitsapi.bitnissen.com/playlist');
  let NewPlaylist = playlistReq.data.playlist;
  NewPlaylist = NewPlaylist.reverse();

  // probably midnight
  if (NewPlaylist.length < Playlist.length) {
    Playlist.splice(0);
  }

  const initialSize = Playlist.length;
  if (initialSize === NewPlaylist.length) return; // nothing to do

  for (let i = initialSize; i < NewPlaylist.length; i += 1) {
    Playlist.push(NewPlaylist[i]);
  }
};

setInterval(updatePlaylist, 60 * 1000);
updatePlaylist();

export default { playlist: Playlist };
