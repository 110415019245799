<template>

  <div class="banners text-center" style="height: 120px; max-height: 120px; background-color: black;">

      <div class="our-sponsors-badge">
        <h1>
          TAK TIL VORES SPONSORER
        </h1>
      </div>

    <div class="d-flex justify-content-center">
      <div class="current-sponsor text-center">
        <img :src="src" alt="" class="img-fluid" style="height: 90px" />
      </div>
    </div>

  </div>

</template>

<script>
  import Banners from '@/services/Banners';

  export default {
    name: 'Banners',
    data: () => Banners,
  };

</script>

<style lang="scss" scoped>
  .our-sponsors-badge {
    background-color: #141331;
    position: relative;
    height: 30px;

    h1 {
      line-height: 30px;
      padding: 0;
      margin: 0;
      color: white;
      font-family: open_sansextrabold;
      font-weight: normal;
      font-size: 18px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-image: linear-gradient(rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.2) 100%);
      pointer-events: none;
    }
  }
</style>
