const Axios = require('axios');

const CurrentBanner = { src: null, idx: -1 };
const Banners = [];

const fetchBanners = async () => {
  const req = await Axios.get('https://vinylhitsapi.bitnissen.com/banners');
  req.data.forEach((banner) => {
    Banners.push(banner);
  });

  const nextBanner = () => {
    CurrentBanner.idx += 1;
    let banner = Banners[CurrentBanner.idx];
    if (!banner) {
      CurrentBanner.idx = 0;

      // eslint-disable-next-line prefer-destructuring
      banner = Banners[0];
    }
    CurrentBanner.src = banner;
  };
  setInterval(nextBanner, 10000);
  nextBanner();
};
fetchBanners();

export default CurrentBanner;
