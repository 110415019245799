<template>
  <!-- ON AIR -->
  <div class="on-air d-flex flex-column">

    <div class="d-flex">
      <div class="on-air-badge flex-grow-0 d-inline-flex flex-column justify-content-center align-items-center">
        <h1 style="font-size: 24px">
          ON<br />AIR
        </h1>
      </div>
      <div class="current-on-air flex-fill justify-center d-flex flex-column">
        <h4 class="flex-grow-0" style="font-size: 20px">{{ title }}</h4>
        <p class="flex-grow-0" style="font-size: 14px">
          {{ description }}
        </p>
      </div>
    </div>

  </div>
  <!-- ON AIR -->
</template>

<script>
  import OnAir from '@/services/OnAir';

  export default {
    name: 'OnAir',
    data: () => OnAir,
  };

</script>

<style lang="scss" scoped>

  .on-air {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      //background-image: linear-gradient(rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.2) 100%);
      pointer-events: none;
    }
  }

  .on-air-badge {
    background-color: #141331;
    padding: 10px 15px 12px 15px;
    border-bottom-right-radius: 10px 10px;

    h1 {
      line-height: 0.9em;
      padding: 0;
      margin: 0;
      color: white;
      font-family: open_sansextrabold;
      font-weight: normal;
    }
  }

  .current-on-air {
    padding: 5px 10px;

    h4 {
      margin-bottom: 0;
      color: white;
      font-family: open_sansextrabold;
      font-weight: normal;
    }
    p {
      margin-bottom: 0;
      color: #aaa;
      font-weight: bold;
      font-family: open_sansregular;
      font-weight: normal;
    }
  }

</style>
