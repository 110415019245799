const Axios = require('axios');

const OnAir = {
  title: 'Indlæser...',
  description: '',
};

const updateOnAir = async () => {
  const req = await Axios.get('https://vinylhitsapi.bitnissen.com/on-air');

  if (OnAir.title !== req.data.title) {
    OnAir.title = req.data.title;
    OnAir.description = req.data.description;
  }
};

setInterval(updateOnAir, 15 * 1000);
updateOnAir();

export default OnAir;
