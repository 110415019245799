import Vue from 'vue';
import Router from 'vue-router';

// import Hello from '@/components/Hello';
import RadioPlayer from '@/pages/RadioPlayer';
import Playlist from '@/pages/Playlist';

Vue.use(Router);

export const routeInfo = {
  playlistIsOpen: false,
};

export default new Router({
  routes: [
    {
      path: '/',
      name: 'RadioPlayer',
      component: RadioPlayer,
    },
    {
      path: '/playlist',
      name: 'Playlist',
      component: Playlist,
    },
  ],
});
