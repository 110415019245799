<template>

  <div class="d-flex flex-fill flex-column bg-disco">
    <OnAir class="flex-grow-0" style="z-index: 1; position: relative"></OnAir>
    <CurrentTrack class="flex-fill"></CurrentTrack>
    <Banners class="flex-grow-0"></Banners>
  </div>

</template>

<style lang="scss" scoped>
.bg-disco {
  margin-top: -10px;
  padding-top: 10px;
  background-image: url('../assets/vinyl-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>

<script>
  import OnAir from './../components/OnAir';
  import CurrentTrack from './../components/CurrentTrack';
  import Banners from './../components/Banners';

  export default {
    components: {
      OnAir,
      CurrentTrack,
      Banners,
    },
    mounted: () => {
      if (navigator && navigator.splashscreen) {
        setTimeout(() => {
          navigator.splashscreen.hide();
        }, 500);
      }
    },
  };

</script>
