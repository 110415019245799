import Player from './Player';

const Axios = require('axios');
const Qs = require('qs');

const coverUrlFallback = '/img/icon-heart.png';

let nextApiRefreshTime = null;
let lastApiResponse = null;

const CurrentTrack = {
  title: 'Indlæser...',
  artist: '',
  coverUrl: '',
};

let previousCoverUrlRes = null;
const updateCurrentPlaying = async (nextUp) => {
  // which is next up?
  let [track] = nextUp;

  nextUp.forEach((aTrack) => {
    if (new Date().getTime() > aTrack.startTime - 5000) {
      track = aTrack;
    }
  });
  if (!track) {
    document.title = 'VinylHits';
    return;
  }

  const recreateMc = (CurrentTrack.title !== track.title);

  CurrentTrack.title = track.title;
  CurrentTrack.artist = track.artist;

  document.title = `${CurrentTrack.artist} - ${CurrentTrack.title}`;

  // get cover
  const coverQs = Qs.stringify({
    q: `${track.artist} - ${track.title}`.toLowerCase(),
  });
  try {
    const coverUrlApi = `https://vinylhitsapi.bitnissen.com/coverart?${coverQs}`;
    if (previousCoverUrlRes !== coverUrlApi) {
      const coverRes = await Axios.get(coverUrlApi);

      if (coverRes.data && coverRes.data.album_cover) {
        CurrentTrack.coverUrl = coverRes.data.album_cover;
      } else {
        CurrentTrack.coverUrl = coverUrlFallback;
      }
    }
    previousCoverUrlRes = coverUrlApi;
  } catch (e) {
    CurrentTrack.coverUrl = coverUrlFallback;
  }

  if (recreateMc) Player.recreateMc();
};


const refreshApi = async () => {
  if (nextApiRefreshTime && nextApiRefreshTime > new Date().getTime()) {
    updateCurrentPlaying(lastApiResponse.nextUp);
    return;
  }

  const apiResponse = (await Axios.get('https://vinylhitsapi.bitnissen.com/playing')).data;
  if (apiResponse && apiResponse.nextUp) {
    nextApiRefreshTime = apiResponse.nextRefresh;
    lastApiResponse = apiResponse;
    updateCurrentPlaying(apiResponse.nextUp);
  }
};
setInterval(refreshApi, 5000);
refreshApi();


export default CurrentTrack;
