<template>
  <div class="d-flex flex-column">
    <div class="text-center text-white current-track flex-grow-0">
      <h5 style="font-size: 18px">{{ artist }}</h5>
      <h2 style="font-size: 24px">{{ title }}</h2>
    </div>

    <div class="cover-outer d-flex justify-content-center align-items-center flex-fill">
      <img v-if="coverUrl" v-bind:src="coverUrl" alt="">
    </div>
  </div>
</template>

<script>
  import Playing from '../services/Playing';

  export default {
    name: 'CurrentTrack',
    data: () => Playing,
  };

</script>

<style lang="scss" scoped>
  .current-track {
    position: relative;
    &:before {
      background-color: rgba(25,25,25,0.3);
      backdrop-filter: blur(5px);
      content: '';
      position: absolute;
      height: 300%;
      width: 100%;
      bottom: 0;
      left: 0;
    }
    padding: 10px 15px 12px 15px;
  }

  h5, h2 {
    position: relative;
    margin-bottom: 0;
    color: white;
    font-family: open_sansextrabold;
    font-weight: normal;
    z-index: 2;
  }

  h5 {
    text-transform: uppercase;
  }

  .cover-outer {
    position: relative;
    padding: 30px;

    img {
      position: absolute;
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      top: 30px;
      left: 30px;
      object-position: center center;
      object-fit: contain;
      filter: drop-shadow(0px 0px 1px rgba(0,0,0,.3))
      drop-shadow(0px 0px 10px rgba(0,0,0,0.7));
    }
  }
</style>
