<template>
  <div class="d-flex top-nav p-3">

    <router-link class="logo align-self-start flex-fill" :to="{ name: 'RadioPlayer' }"
                 v-bind:class="{ playing: Player.isPlaying }">
      <div class="inner"></div>
    </router-link>

    <div class="buttons flex-grow-0 d-flex">
      <div class="nav-btn playlist-outer">
        <router-link v-if="routeInfo.playlistIsOpen" :to="{ name: 'RadioPlayer' }" class="playlist-btn is-open"></router-link>
        <router-link v-if="!routeInfo.playlistIsOpen" :to="{ name: 'Playlist' }" class="playlist-btn"></router-link>
      </div>

      <a href="#play-pause" class="nav-btn play-btn"
         v-bind:class="{ playing: Player.isPlaying, loading: Player.isLoading }" @click.prevent="Player.togglePlay"></a>
    </div>

  </div>
</template>

<style lang="scss" scoped>
  .top-nav {
    background: linear-gradient(#191554 0%, #141331 100%);
    min-height: 58px;
		box-sizing: content-box;
    position: relative;
    z-index: 1;
  }

  .logo {
    display: block;
    width: 60px;
    height: 60px;

    .inner {
      width: 60px;
      height: 60px;
      background-image: url("../assets/logo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      transform: rotateZ(0deg);
      transform-origin: 50% 50%;
    }

    &.playing {
      .inner {
        animation: rotate-logo 5s infinite linear;
      }
    }
  }

  .buttons {
    .nav-btn {
      width: 60px;
      height: 60px;
      margin: 0px 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display: block;

      &.playlist-outer {
        position: relative;

        .playlist-btn {
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("../assets/playlist.svg");

          &.is-open {
            background-image: url("../assets/playlist-is-open.svg");
          }
        }
      }

      &.play-btn {
        background-image: url("../assets/play.svg");
      }

      &.play-btn.loading {
        background-image: url("../assets/beating-heart-preloader.svg");
      }

      &.play-btn.playing {
        background-image: url("../assets/pause.svg");
      }
    }
  }

  @keyframes rotate-logo {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
</style>

<script>
  import Player from '@/services/Player';
  import { routeInfo } from '../router/index';

  export default {
    name: 'Nav',
    data: () => ({
      Player,
      routeInfo,
    }),
  };
</script>
