// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';

Vue.config.productionTip = false;

// add cordova.js only if serving the app through file://
if (window.location.protocol === 'file:' || window.location.port === '3000') {
  const cordovaScript = document.createElement('script');
  cordovaScript.setAttribute('type', 'text/javascript');
  cordovaScript.setAttribute('src', 'cordova.js');
  document.body.appendChild(cordovaScript);
}

/* eslint-disable no-new */
const app = new Vue({
  router,
  template: '<App/>',
  components: {
    App,
  }
});
app.$mount('#app');

document.addEventListener('deviceready', () => {
  const { permissions } = window.cordova.plugins;
  permissions.requestPermissions([
    permissions.WAKE_LOCK,
    permissions.FOREGROUND_SERVICE,
    permissions.BLUETOOTH_ADMIN,
    permissions.BLUETOOTH,
    permissions.USE_FULL_SCREEN_INTENT,
  ]);
}, false);
