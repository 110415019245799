import { render, staticRenderFns } from "./Playlist.vue?vue&type=template&id=0b8fcdbe&scoped=true&"
import script from "./Playlist.vue?vue&type=script&lang=js&"
export * from "./Playlist.vue?vue&type=script&lang=js&"
import style0 from "./Playlist.vue?vue&type=style&index=0&id=0b8fcdbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8fcdbe",
  null
  
)

export default component.exports